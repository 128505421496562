import { CreateSlotSchema } from "src/schemas/create-slot";
import { z } from "zod";
import { axiosAuth } from "./common";
import { BASE_URL } from "./appConst";
import { EditSlotSchema } from "src/schemas/edit-slot";
import axios from "axios";
import {
  CreateMultipleQuestionsSchema,
  CreateSingleQuestionSchema,
} from "src/schemas/create-questions";
import { EditQuestionSchema } from "src/schemas/edit-question";
import { CreateIndividualSlotSchema } from "src/schemas/create-individual-slot";
import { AddQualifyingMarksSchema } from "src/screens/Dashboard/Exams/Results/add-qualifyingmarks-form";

export const CreateExamSlot = async (
  values: z.infer<typeof CreateSlotSchema>
) => {
  const api = axiosAuth();

  function reformatDate(originalDate) {
    const dateParts = originalDate.split("-"); // Split the date string by '-'
    return `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`; // Rearrange the parts
  }

  const response = await api.post(`${BASE_URL}/add-slot`, {
    catagory: values.post,
    state: values.state,
    max_capacity: values.maxCapacity,
    start_time: values.startTime,
    end_time: values.endTime,
    date: reformatDate(values.date),
    zoom_link: values.zoom_link,
    slotname: values.slotName,
  });
  return response.data;
};

export const CreateIndividualSlot = async (
  values: z.infer<typeof CreateIndividualSlotSchema>
) => {
  const api = axiosAuth();

  function reformatDate(originalDate) {
    const dateParts = originalDate.split("-"); // Split the date string by '-'
    return `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`; // Rearrange the parts
  }

  const response = await api.post(`${BASE_URL}/schedule-slot-custom`, {
    postName: values.post,
    state: values.state,
    emailId: values.email,
    start_time: values.startTime,
    date: reformatDate(values.date),
    slotname: values.slotName,
    zoom_link: values.zoom_link,
    end_time: values.endTime,
  });

  return response.data;
};

export const GetSlots = async (
  page: number = 1,
  pageSize: number = 10,
  query: string
) => {
  const api = axiosAuth();

  const response = await api.get(
    `${BASE_URL}/all-slot?page=${page}&pagesize=${pageSize}&${query}`
  );

  return response.data.data.slots;
};

export const PublishSlot = async (slotId: number) => {
  const api = axiosAuth();
  const response = await api.patch(`${BASE_URL}/publish-slot/${slotId}`);
  return response.data;
};

export const DeleteSlot = async (slotId: number) => {
  const api = axiosAuth();
  const response = await api.delete(`${BASE_URL}/delete-slot/${slotId}`);
  return response.data;
};

export const EditSlot = async (
  values: z.infer<typeof EditSlotSchema>,
  id: number
) => {
  const api = axiosAuth();
  const response = await api.patch(`${BASE_URL}/edit-slot/${id}`, {
    catagory: values.post,
    state: values.state,
    max_capacity: parseInt(values.maxCapacity),
    start_time: values.startTime.toString(),
    end_time: values.endtime.toString(),
    date: values.date.toString(),
    zoom_link: values.zoom_link,
    slotname: values.slotName,
  });
  return response.data;
};

export const markSlotasDone = async (ids: number[]) => {
  const api = axiosAuth();

  const response = await api.patch(`${BASE_URL}/slot-mark`, {
    slots: ids,
  });

  return response.data;
};

export const uploadQuestions = async (
  values: z.infer<typeof CreateMultipleQuestionsSchema>
) => {
  const api = axiosAuth();

  const response = await api.get(
    `${BASE_URL}/import-questions?file=${values.questionsCsv.name}`
  );

  const { url } = response.data.data;

  await axios.put(url, values.questionsCsv);

  return { status: response.status, message: response.data.message };
};

export const getQuestions = async (
  page: number = 1,
  pageSize: number = 10,
  query: string
) => {
  try {
    const api = axiosAuth();
    const response = await api.get(
      `${BASE_URL}/get-questions?page=${page}&pagesize=${pageSize}&${query}`
    );

    return response.data.data.questions;
  } catch (error) {
    console.log(error);
    return {
      msg: "something went wrong, error occured",
    };
  }
};

export const deleteQuestionById = async (id: number) => {
  try {
    const api = axiosAuth();
    const response = await api.delete(`${BASE_URL}/delete-question/${id}`);

    return response.data;
  } catch (error) {
    console.log("Something went wrong!");
  }
};

export const editQuestion = async (
  values: z.infer<typeof EditQuestionSchema>,
  id: number
) => {
  try {
    const api = axiosAuth();
    const response = await api.patch(`${BASE_URL}/question/${id}`, {
      question: values.question,
      option1: values.option1,
      option2: values.option2,
      option3: values.option3,
      option4: values.option4,
      catagory: values.catagory,
      type: values.type,
      marks: parseInt(values.marks),
      answer: values.answer,
      part: values.part,
    });

    return response.data;
  } catch (error) {
    console.log("Something went wrong!");
  }
};

export const uploadSingleQuestion = async (
  values: z.infer<typeof CreateSingleQuestionSchema>
) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/add-single-question`, {
    ...values,
    marks: parseInt(values.marks),
  });

  return { status: response.status, message: response.data.message };
};

export const terminateExam = async (id: number) => {
  try {
    const api = axiosAuth();

    const response = await api.patch(`${BASE_URL}/exam-termination/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const proctorsNoteApi = async (id: number, reason: string) => {
  try {
    const api = axiosAuth();

    const response = await api.patch(
      `${BASE_URL}/schedule/proctor-note/${id}`,
      {
        proctorsNote: reason,
      }
    );

    return response;
  } catch (error) {
    console.error(error, "Something went wrong!");
  }
};

export const getExamSubmission = async ( pageNumber: number, pageSize: number, query: string) => {
  try {
    const api = axiosAuth();

    const response = await api.get(
      `${BASE_URL}/get-exam-submission?date_asc=true&page=${pageNumber}&pagesize=${pageSize}&${query}`
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getExaminerSubmissions = async ( pageNumber: number, pageSize: number,examinerPost: string, query: string, ) => {
  try {
    const api = axiosAuth();

    const response = await api.get(
      `${BASE_URL}/get-exam-submission?page=${pageNumber}&pagesize=${pageSize}&date_asc=true&post=${examinerPost}&${query}`
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getResults = async (pageNumber: number, pageSize: number, query: string ) => {
  try {
    const api = axiosAuth();

    const response = await api.get(
      `${BASE_URL}/get-qualified-candidate?page=${pageNumber}&pagesize=${pageSize}&${query}`
    );

    return response.data;

  } catch (error) {
    console.error(error);
  }
}

export const getSubmissionByAdmit = async (admitCardNumber: string) => {
  try {
    const api = axiosAuth();

    const response = await api.get(
      `${BASE_URL}/get-exam-submission-by-admit?admitcardnumber=${admitCardNumber}`
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const saveSubmissionMarks = async (id: number, marks: number) => {
  try {
    const api = axiosAuth();

    const response = await api.post(`${BASE_URL}/check-answer/${id}`, {
      marks_obtained: marks,
    });

    return response.data;
  } catch (error) {
    console.error(error, "Something went wrong!");
  }
};

interface Paper {
  examination_id: string;
  admitcard_number: string;
  post: string;
  marks: number;
  candidate_name: string;
  state: string;
}

export const addQualifiedCandidate = async (paper: Paper) => {
  try {
    const api = axiosAuth();

    const response = await api.post(`${BASE_URL}/add-qualifiedcandidate`, {
      admitcard_number: paper.admitcard_number,
      candidate_name: paper.candidate_name,
      examination_id: paper.examination_id,
      marks: paper.marks,
      post: paper.post,
      state: paper.state,
    });

    return response.data;

  } catch (error) {
    console.error(error);
    throw new Error("Failed to save the Paper!");
  }
}

export const publishResultAPI = async (ids: number[]) => {
  const api = axiosAuth();

  const response = await api.patch(`${BASE_URL}/publish-result`, {
    ids: ids,
  });

  return response.data;
};

export const editQualifiedCandidate = async (result:string, id: number) => {
  try {
    const api = axiosAuth();

    const response = await api.patch(
      `${BASE_URL}/edit-qualified-candidate/${id}}`,
      {
        result: result,
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong!");
  }
}

export const editResultMarks = async (marks: string, sl: number) => {
  const api = axiosAuth();

  const response = await api.patch(`${BASE_URL}/edit-qualified-candidate/${sl}`, 
    {
      marks: Number(marks),
    }
  );

  return response.data;
}

export const editQualifyingMarks = async (values: z.infer<typeof AddQualifyingMarksSchema>) => {
  try {
    const api = axiosAuth();

    const response = await api.patch(`${BASE_URL}/result/qualified-marks`, 
      {
        state: values.state,
        post: values.post,
        marks: parseInt(values.qualifyingMarks),
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong!");
  }
};
