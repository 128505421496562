import { Link } from "react-router-dom"
import { DashboardDefaultContainer } from "../dashboard-default-container"
import FrontDeskHeader from "./front-desk-header"
import { BookUser, ChevronRight, Send, Users } from "lucide-react";
import { IdCardIcon, ReaderIcon } from "@radix-ui/react-icons";


const FrontDeskView = () => {
  return (
    <DashboardDefaultContainer>
      <FrontDeskHeader />
      <div className="flex gap-3 justify-evenly py-4">
        <Link to="/view-candidates">
          <div className="w-[260px] h-[111px] border border-black rounded-lg hover:bg-zinc-200 hover:shadow-lg hover:shadow-slate-400 flex justify-center items-center gap-3 flex-col">
            <h1 className="text-xl font-semibold flex gap-3">View Candidates <ChevronRight className="size-8" /></h1>
            <Users /> 
          </div>
        </Link>
        <Link to="/view-ebook-users">
          <div className="w-[260px] h-[111px] border border-black rounded-lg hover:bg-zinc-200 hover:shadow-lg hover:shadow-slate-400 flex justify-center items-center gap-3 flex-col">
            <h1 className="text-xl font-semibold flex gap-3">View E-Book Users <ChevronRight className="size-8" /></h1>
            <BookUser /> 
          </div>
        </Link>
        <Link to="/view-admit-cards">
          <div className="w-[260px] h-[111px] border border-black rounded-lg hover:bg-zinc-200 hover:shadow-lg hover:shadow-slate-400 flex justify-center items-center gap-3 flex-col">
            <h1 className="text-xl font-semibold flex gap-3">View Admit Cards <ChevronRight className="size-8 " /></h1>
            <IdCardIcon className="w-[25px] h-[25px]" />{" "}
          </div>
        </Link>
        <Link to="/view-results">
          <div className="w-[260px] h-[111px] border border-black rounded-lg hover:bg-zinc-200 hover:shadow-lg hover:shadow-slate-400 flex justify-center items-center gap-3 flex-col">
            <h1 className="text-xl font-semibold flex gap-3">View Result <ChevronRight className="size-8 " /></h1>
            <ReaderIcon className="w-[25px] h-[25px]" />{" "}
          </div>
        </Link>
        <Link to="/send-email">
          <div className="w-[260px] h-[111px] border border-black rounded-lg hover:bg-zinc-200 hover:shadow-lg hover:shadow-slate-400 flex justify-center items-center gap-3 flex-col">
            <h1 className="text-xl font-semibold flex gap-3">Send Notification <ChevronRight className="size-8" /></h1>
            <Send /> 
          </div>
        </Link>
      </div>
    </DashboardDefaultContainer>
  );
}

export default FrontDeskView