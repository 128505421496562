import { ManageResultDetailsHeader } from "./ManageResultDetailsHeader";
import { useEffect, useState } from "react";
import { ExamSubmission } from "src/redux/types/single-submission";
import {
  editQualifiedCandidate,
  editResultMarks,
  getSubmissionByAdmit,
} from "src/redux/api/_exams.api";
import { useAppSelector } from "src/redux/hooks";
import { UPLOADED_ANSWER_URL } from "src/redux/api/appConst";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "src/components/form/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/input/Select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface ManageResultDetailViewProps {
  defaultView: () => void;
}

interface FormProps {
  result: string;
}

const ManageResultDetailView = ({
  defaultView,
}: ManageResultDetailViewProps) => {
  const [currentPaper, setCurrentPaper] = useState<ExamSubmission[]>();

  const mcqPosts = ["DWO", "AWO"];
  const [editResult, setEditResult] = useState(false);
  const [changeMarks, setChangeMarks] = useState(false);
  const [newMarks, setNewMarks] = useState<string>("");

  const { currentResult } = useAppSelector((state) => state.userReducer);

  const optionalQuestionSubmissions: ExamSubmission[] = [];
  const shortQuestionSubmissions: ExamSubmission[] = [];
  const longQuestionSubmissions: ExamSubmission[] = [];

  const fetchSubmissionByAdmit = async () => {
    try {
      const response = await getSubmissionByAdmit(
        currentResult.admitcard_number
      );

      if (response?.message.toLowerCase() === "success") {
        setCurrentPaper(response.data.exam_submissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSubmissionByAdmit();
  }, []);

  const questionTypeSeparator = (paper: ExamSubmission[]) => {
    paper.forEach((currentPaper) => {
      const questionType = currentPaper.question
        ? currentPaper.question.type.toLowerCase()
        : "";

      switch (questionType) {
        case "optional":
          optionalQuestionSubmissions.push(currentPaper);
          break;
        case "short":
          shortQuestionSubmissions.push(currentPaper);
          break;
        case "desc":
          longQuestionSubmissions.push(currentPaper);
          break;
        default:
          break;
      }
    });
  };

  if (currentPaper) {
    questionTypeSeparator(currentPaper!);
  }

  const form = useForm<FormProps>();

  const handleEditResult = async (values: FormProps) => {
    try {
      const response = await editQualifiedCandidate(
        values.result,
        currentResult.sl
      );

      if (response.message.toLowerCase() === "success") {
        toast.success("Result Saved Successfully!");
      }
    } catch (error) {
      toast.error("Failed to save Result!");
    }
  };

  const handleSaveNewMarks = async() => {
    try {
      const response = await editResultMarks(newMarks, currentResult.sl);

      if(response.message.toLowerCase() === "success") {
        toast.success("Marks Saved Successfully!");
      }
    } catch (error) {
      toast.error("Failed to save Marks");
      console.error(error);
    }
  };

  const optionalMarksTable = () => {
    if (
      Array.isArray(optionalQuestionSubmissions) &&
      optionalQuestionSubmissions.length > 0
    ) {
      return optionalQuestionSubmissions.map((paper, index) => (
        <tr key={index}>
          <td>
            {paper.exam_submission.questionNumber} .{paper.question.question}
          </td>
          <td>{paper.exam_submission.answer}</td>
          <td className="text-center">
            {paper.exam_submission.marks_obtained}
          </td>
        </tr>
      ));
    }
    return null;
  };

  const shortMarksTable = () => {
    if (
      Array.isArray(shortQuestionSubmissions) &&
      shortQuestionSubmissions.length > 0
    ) {
      return shortQuestionSubmissions.map((paper, index) => (
        <tr key={index}>
          <td>
            {index + 1}. {paper.question.question}
          </td>
          <td className="w-[40rem]">{paper.exam_submission.answer}</td>
          <td className="text-center">
            {paper.exam_submission.marks_obtained}
          </td>
        </tr>
      ));
    }
    return null;
  };

  const DisplayLongAnswers = () => {
    if (
      Array.isArray(longQuestionSubmissions) &&
      longQuestionSubmissions.length > 0
    ) {
      return (
        <div>
          {longQuestionSubmissions.map((paper, index) => {
            return (
              <div className="border-b border-slate-300 p-2 my-6 " key={index}>
                <h1 className="text-lg">
                  <span>{index + 1}</span>.{" "}
                  <span>{paper.question.question}</span>
                </h1>
                {!!paper.exam_submission.answerUrl ? (
                  <img
                    src={`${UPLOADED_ANSWER_URL}/${paper.exam_submission.answerUrl}`}
                    alt="uploaded answer"
                    className="aspect-[3/4] m-auto max-w-[1080px] my-4"
                  />
                ) : !!paper.exam_submission.answer ? (
                  <h1>{paper.exam_submission.answer}</h1>
                ) : (
                  <h1 className="text-xl font-bold text-center">
                    Not Attempted.
                  </h1>
                )}

                <div className="text-end text-xl">
                  {paper.exam_submission.marks_obtained && (
                    <h1 className="text-xl font-bold text-end pr-4">
                      {" "}
                      Obtained Marks: {paper.exam_submission.marks_obtained}
                    </h1>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <ManageResultDetailsHeader defaultView={defaultView} />
      <div>
        <div className="flex gap-4 mb-8 pl-6">
          <div className="font-semibold text-xl">
            <h1>Candidate Name</h1>
            <h1>Post Name</h1>
            <h1>State</h1>
            <h1>Admit Card Number</h1>
          </div>
          <div className="font-semibold text-xl">
            <h1 className="uppercase">{currentResult.candidate_name}</h1>
            <h1>{currentResult.post}</h1>
            <h1>{currentResult.state}</h1>
            <h1>{currentResult.admitcard_number}</h1>
          </div>
          <div className="ml-20 text-xl font-bold flex gap-24">
            <div className="flex flex-col justify-center gap-4">
              <h1>
                Qualifying Marks: <span>{currentResult.qualified_marks}</span>
              </h1>
              <h1>
                Total Marks Obtained: <span>{ newMarks? newMarks : currentResult.marks}</span>
              </h1>
            </div>
            <div className="flex items-center gap-4">
              <h1 className="">Result:</h1>
              <span
                className={`${
                  currentResult.result?.toLowerCase() === "qualified"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {currentResult.result}
              </span>

              {currentResult.result ? (
                !editResult ? (
                  <button
                    onClick={() => setEditResult(true)}
                    className="px-2 py-2 border border-black text-sm font-medium hover:bg-amber-300 rounded-lg bg-amber-200"
                  >
                    Edit Result
                  </button>
                ) : (
                  <div>
                    <Form {...form}>
                      <form
                        className="flex gap-8"
                        onSubmit={form.handleSubmit(handleEditResult)}
                      >
                        <div>
                          <FormField
                            name="result"
                            control={form.control}
                            render={({ field }) => (
                              <FormItem>
                                <Select
                                  onValueChange={field.onChange}
                                  value={field.value}
                                >
                                  <FormControl>
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select Result" />
                                    </SelectTrigger>
                                  </FormControl>
                                  <SelectContent>
                                    <SelectItem value="QUALIFIED">
                                      QUALIFIED
                                    </SelectItem>
                                    <SelectItem value="NOT QUALIFIED">
                                      NOT QUALIFIED
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                              </FormItem>
                            )}
                          />
                        </div>
                        <button
                          type="submit"
                          className="px-3 py-1 text-lg rounded-md bg-green-200 hover:bg-green-500 hover:border hover:border-green-500 border border-black"
                        >
                          SAVE RESULT
                        </button>
                      </form>
                    </Form>
                  </div>
                )
              ) : (
                <div>
                  <Form {...form}>
                    <form
                      className="flex gap-8"
                      onSubmit={form.handleSubmit(handleEditResult)}
                    >
                      <div>
                        <FormField
                          name="result"
                          control={form.control}
                          render={({ field }) => (
                            <FormItem>
                              <Select
                                onValueChange={field.onChange}
                                value={field.value}
                              >
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select Result" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  <SelectItem value="QUALIFIED">
                                    QUALIFIED
                                  </SelectItem>
                                  <SelectItem value="NOT QUALIFIED">
                                    NOT QUALIFIED
                                  </SelectItem>
                                </SelectContent>
                              </Select>
                            </FormItem>
                          )}
                        />
                      </div>
                      <button
                        type="submit"
                        className="px-3 py-1 text-lg rounded-md bg-green-200 hover:bg-green-500 hover:border hover:border-green-500 border border-black"
                      >
                        SAVE RESULT
                      </button>
                    </form>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">Optional Answers Marks</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Marks Obtained</th>
              </tr>
            </thead>
            <tbody>{optionalMarksTable()}</tbody>
          </table>
        </div>
        { !mcqPosts.includes(currentResult.post) && (
          <div className="my-8 border-t border-slate-300">
            <h1 className="text-xl font-semibold my-6">Short Answers Marks</h1>
            <table className="table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Marks Obtained</th>
                </tr>
              </thead>
              <tbody>{shortMarksTable()}</tbody>
            </table>
          </div>
        )}

        { !mcqPosts.includes(currentResult.post) && (
          <div className="my-8 border-t border-slate-300">
            <h1 className="text-xl font-semibold my-6">
              Descriptive Answers Marks
            </h1>
            <DisplayLongAnswers />
          </div>
        )}

        <div className="my-12 text-2xl font-bold flex justify-end gap-8 items-center">
          <h1>Total Marks Obtained: { newMarks ? newMarks : currentResult.marks}</h1>
            <div>
              {changeMarks ? (
                <div className="flex gap-3">
                  <input
                    type="text"
                    className="size-14 border border-green-500 text-center p-2 rounded-md font-medium"
                    onChange={(e) => setNewMarks(e.target.value)}
                  />
                  <button
                    className="px-3 py-2 w-fit border border-green-600 bg-green-200 text-sm font-medium rounded-md"
                    onClick={handleSaveNewMarks}
                  >
                    Save Marks
                  </button>
                </div>
              ) : (
                <button
                  className="px-3 py-2 border border-gray-500 bg-amber-200 hover:bg-amber-300 text-sm font-medium rounded-md"
                  onClick={() => setChangeMarks(true)}
                >
                  Change Marks
                </button>
              )}
            </div>
        </div>
      </div>
    </>
  );
};

export default ManageResultDetailView;
