import { Loader } from "lucide-react";

const Loading = () => {
  return (
    <tbody>
      <tr>
        <td colSpan={12} align="center">
          <span className="flex justify-center gap-2 p-4 text-xl items-center">
            Loading <Loader size={28} />
          </span>
        </td>
      </tr>
    </tbody>
  );
};

export default Loading;
