/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./Login/Login";
import { NavBar } from "../components";

import { LogOut } from "./Login/Logout";
import React, { useEffect } from "react";
import { NotFoundPage } from "./NotFoundPage";
import { ResetPassword } from "./Login/ResetPassword";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userLogin } from "../redux/reducers";
import { Leads } from "./Dashboard/Leads";
import { EmailNotification } from "./Dashboard/Notifications";
import { ManageCandidates } from "./Dashboard/ManageCandidates";
import { BookUsersListView } from "./Dashboard/ManageEbookUsers/BookUsersListView";
import { BookUserDetail } from "./Dashboard/ManageEbookUsers/BookUserDetail";
import { CreateEbookUser } from "./Dashboard/ManageEbookUsers/CreateEbookUser";
import { QuestionBankListView } from "./Dashboard/Exams/ManageQuestions";
import { ManageSlotsListView } from "./Dashboard/Exams/ManageSlots";
import { ExamsView } from "./Dashboard/Exams";
import { ManageAdmitCardView } from "./Dashboard/Exams/AdmitCard";
import { ManageResultsView } from "./Dashboard/Exams/Results/ManageResultsView";
import ManageExamView from "./Dashboard/Exams/ManageExams";
import CheckExams from "./Dashboard/Exams/Results/CheckExams";
import jwtDecode from "jwt-decode";
import { Profile } from "./Dashboard/Profile/Profile";
import FrontDeskView from "./Dashboard/FrontDesk";
import { ViewCandidates } from "./Dashboard/FrontDesk/ViewCandidates";
import { ViewEbookUserDetails } from "./Dashboard/FrontDesk/ViewEbookUserDetails";
import { ViewEbookUsers } from "./Dashboard/FrontDesk/ViewEbookUsers";
import { ViewAdmitCards } from "./Dashboard/FrontDesk/ViewAdmitCards";
import ExaminerDashboard from "./Dashboard/Examiner";


type PageName =
  | ""
  | "*"
  | "landing"
  | "floor-tables"
  | "users"
  | "profile"
  | "my-profile"
  | "menu-editor"
  | "chef-server"
  | "dashboard"
  | "notification"
  | "qrcode"
  | "orders"
  | "verify"
  | "logout"
  | "location"
  | "self-reset-password"
  | "project/:id"
  | "leads";

interface PageType {
  readonly pageName: PageName;
  component: JSX.Element;
}

const availablePages: PageType[] = [
  {
    pageName: "leads",
    component: <Leads />,
  },
  {
    pageName: "profile",
    component: <Profile />,
  },

  {
    pageName: "logout",
    component: <LogOut />,
  },
];

interface AllowRoutesProps {
  isAdmin: boolean;
  isProctor: boolean;
  isFrontDesk: boolean;
  isExaminer: boolean;
}

interface DecodedToken {
  "cognito:groups"?: string[];
}


const AllowedRoutes: React.FC<AllowRoutesProps> = ({isAdmin, isProctor, isFrontDesk, isExaminer}) => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      dispatch(userLogin({ token, _id: "" }));
    }
  }, []);

  if (isAdmin) {
    return (
      <Routes>
        <Route path="/dashboard" element= {<ExamsView /> }/>
        <Route path="/manage-candidates" element={<ManageCandidates />} />
        <Route path="/self-reset-password" element={<ResetPassword />} />
        <Route path="/leads" element={<Leads/>} />
        <Route path="/send-email" element={<EmailNotification />} />
        <Route path="/ebook-users" element={<BookUsersListView />} />
        <Route path="/ebook-user" element={<BookUserDetail />} />
        <Route path="/create-ebook-user" element={<CreateEbookUser />} />
        <Route path="/manage-slots" element={<ManageSlotsListView />} />
        <Route path="/manage-questions" element={<QuestionBankListView />} />
        <Route path="/manage-admit" element={<ManageAdmitCardView />} />
        <Route path="/manage-exam" element={<ManageExamView />} />
        <Route path="/manage-results" element={<ManageResultsView />} />
        <Route path="/check-exam" element={<CheckExams />} />
        <Route path="/exams" element={<ExamsView />} />
        <Route path="/front-desk" element={<FrontDeskView />} />
        <Route path="/view-candidates" element={<ViewCandidates />} />
        <Route path="/view-ebook-users" element={<ViewEbookUsers />} />
        <Route path="/view-ebook-user-details" element={<ViewEbookUserDetails />} />
        <Route path="/view-admit-cards" element={<ViewAdmitCards />} />
        <Route path="/examiner-dashboard" element={<ExaminerDashboard />} />
        <Route path="/" element={<Login />} />
        <Route path="/logout" element={<LogOut />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  } 
  if(isProctor){
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/logout" element={<LogOut />} />
        <Route path="/dashboard" element={<ManageExamView />} />
        <Route path="/manage-exam" element={<ManageExamView />} />
        <Route path="*" element={<Navigate to="/manage-exam" />} />
      </Routes>
    );
  } 
  if (isFrontDesk) {
    return (
      <Routes>
        <Route path="/front-desk" element={<FrontDeskView />} />
        <Route path="/view-candidates" element={<ViewCandidates />} />
        <Route path="/view-ebook-users" element={<ViewEbookUsers />} />
        <Route
          path="/view-ebook-user-details"
          element={<ViewEbookUserDetails />}
        />
        <Route path="/view-admit-cards" element={<ViewAdmitCards />} />
        <Route path="/send-email" element={<EmailNotification />} />
        <Route path="/" element={<Login />} />
        <Route path="/logout" element={<LogOut />} />
        <Route path="*" element={<Navigate to="/front-desk" />} />
      </Routes>
    );
  } 
  if(isExaminer) {
    return (
      <Routes>
        <Route path="/" element ={<Login />}/>
        <Route path="/logout" element ={<LogOut />}/>
        <Route path="/dashboard" element ={<ExaminerDashboard />}/>
        <Route path="/examiner-dashboard" element ={<ExaminerDashboard />}/>
        <Route path="*" element ={<Navigate to="/examiner-dashboard" />}/>
      </Routes>
    )
  }
  if(!isAdmin && !isProctor && !isFrontDesk && !isExaminer) {
    return (
      <Routes>
          <Route path="/self-reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Login />} />
          <Route path="*" element={<NotFoundPage />} />
      </Routes>
    )
  }
  return null;
};

interface RouteManagerProps {}

export const RouteManager: React.FC<RouteManagerProps> = () => {

    const profile = useAppSelector((state) => state.userReducer).userProfile;

    const decoded = profile.token ? jwtDecode<DecodedToken>(profile.token) : "";
    const userGroup = decoded ? decoded["cognito:groups"] : "";

    const AdminAuth = () => {
      if (!profile.token) return false;

      try {
        return userGroup?.includes("ADMIN");
      } catch (err) {
        return false;
      }
    };

    const ProctorAuth = () => {
      if (!profile.token) return false;

      try {
        return userGroup?.includes("PROCTOR");
      } catch (err) {
        return false;
      }
    };

    const FrontDeskAuth = () => {
      if (!profile.token) return false;

      try {
        return userGroup?.includes("FRONT_DESK");
      } catch (error) {
        return false;
      }
    }

    const ExaminerAuth = () => {
      if (!profile.token) return false;

      try {
        const examinerRoles = [
          "EXAMINER_RWO",
          "EXAMINER_ITWO",
          "EXAMINER_HRWOA",
          "EXAMINER_HRWOB",
          "EXAMINER_HRWOC",
          "EXAMINER_HRWOD",
          "EXAMINER_DWO",
          "EXAMINER_AWO",
        ];

        return examinerRoles.some(examiner => userGroup?.includes(examiner)) ;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    const isAdmin = AdminAuth();
    const isProctor = ProctorAuth();
    const isFrontDesk = FrontDeskAuth();
    const isExaminer = ExaminerAuth();

  return (
    <BrowserRouter>
      <NavBar
        isAdmin={isAdmin}
        isProctor={isProctor}
        isFrontDesk={isFrontDesk}
        isExaminer={isExaminer}
      />
      <AllowedRoutes
        isAdmin={isAdmin!}
        isProctor={isProctor!}
        isFrontDesk={isFrontDesk!}
        isExaminer={isExaminer!}
      />
    </BrowserRouter>
  );
};