import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const HeaderTitle = ({title}) => {
  const navigate = useNavigate();

  return (
    <div className="p-4 flex justify-between">
      <p className="text-2xl">{title}</p>
      <div className="join gap-3">
        <button
          className="rounded-lg bg-zinc-300 hover:bg-amber-200 text-xl font-semibold btn-xs sm:btn-sm md:btn-md join-item"
          onClick={() => navigate('/front-desk')}
        >
          <ArrowBack />
          Back
        </button>
      </div>
    </div>
  );
};

export default HeaderTitle;
