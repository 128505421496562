import React, { useEffect, useState} from "react";
import {
  GetEbookUsersApi,
} from "../../../redux/api/_ebook-user.api";
import { useDispatch } from "react-redux";
import { setCurrentEBookuser, setEbookUsers } from "../../../redux/reducers";
import { Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cn } from "../../../utils";
import { toast } from "react-toastify";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/input/Select";
import { CandidateListPagination } from "../ManageCandidates/candidate-list-pagination";
import { useDebounce } from "use-debounce";
import { TrashIcon } from "lucide-react";
import { AxiosError } from "axios";
import { TransactionDetailDialog } from "../ManageEbookUsers/txn-detail-dialog";
import { SelectState } from "../ManageEbookUsers/select-state";
import { SelectPost } from "../ManageEbookUsers/select-post";
import { SelectStatus } from "../ManageEbookUsers/select-status";
import HeaderTitle from "./header-title";

export const ViewEbookUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  const name = searchParams.get("name") || "";
  const email = searchParams.get("email") || "";
  const post = searchParams.get("post") || "";
  const status = searchParams.get("status") || "";
  const pageSize = parseInt(searchParams.get("pageSize") || "25", 10);
  const currentPageNumber = parseInt(searchParams.get("page") || "1", 10);
  const state = searchParams.get("state") || "";

  const [selectedIds, setSelectIds] = useState<number[]>([]);

  const [failSafePageNumber, setFailSafePageNumber] = useState<number>();

  const { ebookUsers } = useAppSelector((state) => state.userReducer);

  const handleNextPage = () => {
    setFailSafePageNumber(currentPageNumber);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPageNumber + 1).toString(),
    });
    fetchEbookUsers().then((users) => {
      dispatch(setEbookUsers(users));
    });
  };

  const handlePrevPage = () => {
    if (currentPageNumber === 1) {
      return;
    }
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (currentPageNumber - 1).toString(),
    });
  };

  const handleJumpToPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
    fetchEbookUsers().then((users) => {
      if (!users || users.length === 0) {
        if (failSafePageNumber) {
          setSearchParams({
            ...Object.fromEntries(searchParams),
            page: failSafePageNumber.toString(),
          });
        }
        toast.error("No more candidates");
        return;
      }
      dispatch(setEbookUsers(users));
    });
  };

  const fetchEbookUsers = async () => {
    try {
      let queryString = "";
      if (email) {
        queryString = `${queryString}&email=${email}`;
      }
      if (name) {
        queryString = `${queryString}&name=${name}`;
      }
      if (post) {
        queryString = `${queryString}&post=${post}`;
      }
      if (state) {
        queryString = `${queryString}&state=${state}`;
      }
      const ebooksUsers = await GetEbookUsersApi(
        queryString,
        currentPageNumber,
        pageSize
      );

      return ebooksUsers;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status === 500) {
          toast.error("Fetch failed please refresh");
        }
      }
      console.error(error);
    }
  };

  const onCopyEmails = async () => {
    const values = selectedIds.values();
    if (values) {
      const candidateList = Array.from(values);
      let emailText = "";
      candidateList.forEach((candidate) => {
        const result = ebookUsers.find((item) => item.sl === candidate);
        if (result) {
          if (emailText.length > 0) {
            emailText = `${emailText},${result.emailId}`;
          } else {
            emailText = result.emailId;
          }
        }
      });
      if (emailText.length > 0) {
        await navigator.clipboard.writeText(emailText);
        toast(`Successfully Copied to Clipboard!`, {
          type: "success",
        });
      } else {
        toast(`Please select items to Copy to Clipboard!`, {
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    fetchEbookUsers().then((ebooksUsers) => {
      let filteredEbookUsers = ebooksUsers;
      if (status) {
        filteredEbookUsers = filteredEbookUsers.filter(
          (user) => user.status === status
        );
      }
      dispatch(setEbookUsers(filteredEbookUsers));
    });
  }, [debouncedSearchParams, currentPageNumber]);

  const DisplayCandidates = React.memo(() => {
    if (Array.isArray(ebookUsers)) {
      return (
        <tbody>
          {ebookUsers.map((candidate) => {
            const { sl, fullName, jobPost, paymentInfo, emailId, status } =
              candidate;
            return (
              <tr key={sl} className="max-w-screen-xl">
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={sl}
                      checked={selectedIds.includes(sl)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSelectIds((prevSelectedIds) => {
                          if (isChecked) {
                            return [...prevSelectedIds, sl];
                          } else {
                            return prevSelectedIds.filter((id) => id !== sl);
                          }
                        });
                      }}
                    />
                  </label>
                </th>
                <td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div>
                        <div className="font-bold">{fullName}</div>
                        <div className="text-sm opacity-50">{emailId}</div>
                      </div>
                    </div>
                  </td>
                </td>
                <td className="font-bold">{candidate.phoneNumber}</td>
                <td className="font-bold">{candidate.address.State}</td>
                <td>
                  <div className="font-bold text-center">{jobPost}</div>
                </td>
                <td>
                  <div className="font-bold">
                    <TransactionDetailDialog bookUser={candidate}>
                      <Button>
                        {Array.isArray(paymentInfo)
                          ? "Txn Details"
                          : "No Txn Details"}
                      </Button>
                    </TransactionDetailDialog>
                  </div>
                </td>
                <td>
                    <div
                      className={cn(
                        "font-bold text-center px-3 py-2 rounded-md text-white w-[100px]",
                        status === "PENDING"
                          ? "bg-red-500 hover:bg-red-500/90"
                          : "bg-green-500 hover:bg-green-500/90"
                      )}
                    >
                      {status}
                    </div>
                </td>
                <td>
                  <Button
                    onClick={() =>
                      navigate(
                        `/send-email?email=${encodeURIComponent(
                          candidate.emailId
                        )}`
                      )
                    }
                  >
                    Notification
                  </Button>
                </td>
                <td>
                  <Button
                    onClick={() => {
                      dispatch(setCurrentEBookuser(candidate));
                      const params = new URLSearchParams(
                        searchParams
                      ).toString();
                      navigate(`/view-ebook-user-details?${params}`);
                    }}
                  >
                    Details
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }

    return null;
  });

  return (
    <div className="container mx-auto px-4 mt-12">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <HeaderTitle title={"View E-Book User"}/>
        <div className="p-4 flex flex-col">
          <div className="flex justify-between w-full items-center">
            <div className="relative w-[300px] px-2 py-3 border rounded-md">
              <input
                placeholder="Search by email"
                type="text"
                className="focus-within:outline-none"
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    email: e.target.value,
                  });
                }}
              />
              <div className="absolute top-1/2 -translate-y-1/2 right-1">
                <Search />
              </div>
            </div>
            <div className="relative w-[300px] px-2 py-3 border rounded-md">
              <input
                placeholder="Search by Name"
                type="text"
                className="focus-within:outline-none"
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    name: e.target.value,
                  });
                }}
              />
              <div className="absolute top-1/2 -translate-y-1/2 right-1">
                <Search />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <SelectState
                value={state}
                onValueChange={(state) => {
                  if (state === "all") {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      state: "",
                    });
                    return;
                  }
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    state,
                    page: "1",
                  });
                }}
              />
              <SelectPost
                value={post}
                onValueChange={(value) => {
                  if (value === "all") {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      post: "",
                    });
                    return;
                  }
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    post: value,
                    page: "1",
                  });
                }}
              />
              <SelectStatus
                value={status}
                onValueChange={(value) => {
                  if (value === "all") {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      status: "",
                    });
                    return;
                  }
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    status: value,
                  });
                }}
              />
              <Button
                onClick={() => {
                  setSearchParams({});
                }}
              >
                <TrashIcon />
              </Button>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={() => {
                        if (selectedIds.length === ebookUsers.length) {
                          setSelectIds([]);
                        } else {
                          setSelectIds(
                            ebookUsers.map((candidate) => candidate.sl)
                          );
                        }
                      }}
                    />
                  </label>
                </th>
                <th>Name</th>
                <th>Contact Number</th>
                <th>State</th>
                <th>Post</th>
                <th className="text-center">Transaction</th>
                <th>Status</th>
                <th>Notification</th>
                <th>View</th>
              </tr>
            </thead>
            <DisplayCandidates />
          </table>
          <div className="flex justify-between items-center">
            <Select
              value={pageSize.toString()}
              onValueChange={(pageSize) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  pageSize,
                });
              }}
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Limit" />
              </SelectTrigger>
              <SelectContent className="bg-white w-[200px]">
                <SelectGroup>
                  {["25", "10"].map((pageSize) => (
                    <SelectItem
                      key={pageSize}
                      value={pageSize}
                      defaultValue={"25"}
                    >
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <div className="flex-grow">
              <CandidateListPagination
                currentPageNumber={currentPageNumber}
                onNext={handleNextPage}
                onPrev={handlePrevPage}
                onJumpToPage={handleJumpToPage}
              />
            </div>
            <div className="join">
              <button
                className="btn btn-xs sm:btn-sm md:btn-md join-item"
                onClick={() => onCopyEmails()}
              >
                Copy Emails
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
